import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, createUrlTreeFromSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { PREDEFINED_ROLES } from "@security/enums/role.enum";

export const canAccessGuard = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const auth = inject(AuthService);
  const isLoggedIn = auth.isLogged();
  let canAccessComponent = false;

  // console.log(state.url);
  // console.log(auth.getRole());
  // console.log(route.data['roles']);

  // Si no hay roles definidos entonces dejamos acceder
  canAccessComponent = route.data['roles']?.includes(auth.getRole()) ?? true;
  // if (route.data['roles']) {
  //   canAccessComponent = route.data['roles'].includes(auth.getRole());
  // } else {
  //   canAccessComponent = true;
  // }

  // console.log(canAccessComponent);

  // return true;
  // return createUrlTreeFromSnapshot(route, ['/autenticacion/acceso']);

  if (isLoggedIn && !canAccessComponent) {
    if (auth.getRole() === PREDEFINED_ROLES.ROLE_MAINTENANCE) {
      return createUrlTreeFromSnapshot(route, ['/equipos/gestion-dispositivos/validate']);
    }
  }

  return isLoggedIn && canAccessComponent ? true : createUrlTreeFromSnapshot(route, ['/autenticacion']);
};
